import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { landingPageCTAClicked } from 'my-core/gtm-events';
import { coursesPath, mcatLandingPath } from 'my-core/routes';

import ButtonXL from 'my-elements/ButtonXL';
import Container from 'my-layout/Container';

import HomePageHeroAnimation from './HomePageHeroAnimation';
import HomePageHeroSearch from './HomePageHeroSearch';

import Button from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { faPlay } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classes = {
  root: ({ breakpoints, constants, palette, spacing }) => ({
    position: 'relative',
    padding: spacing(constants.APPBAR_OFFSET_UNITS, 0, 0),
    backgroundColor: palette.background.dark,
    overflow: 'hidden',

    [breakpoints.down('md')]: {
      padding: spacing(constants.APPBAR_OFFSET_UNITS + 2, 0, 4),
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: spacing(6),
    },
    [breakpoints.only('sm')]: {
      padding: spacing(constants.APPBAR_OFFSET_UNITS + 2, 0, 4),
    },
  }),
  container: ({ breakpoints, palette, spacing }) => ({
    padding: spacing(0, 5),

    [breakpoints.up('md')]: {
      position: 'relative',
      zIndex: 1,
      padding: spacing(10, 5, 13),
      background: `linear-gradient(40deg, ${palette.background.dark} 35%, ${alpha(palette.background.dark, 0)} 65%)`,
    },
  }),
  copySection: ({ breakpoints, palette, spacing }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: spacing(4),

    color: palette.common.white,
    width: '100%',
    maxWidth: 550,

    [breakpoints.only('sm')]: {
      margin: 'auto',
    },

    [breakpoints.up('md')]: {
      maxWidth: 740,
      flex: '0 0 520px',
    },
    [breakpoints.down('md')]: {
      maxWidth: 650,
      alignItems: 'center',
      textAlign: 'center',
    },
  }),
  ctas: ({ breakpoints, spacing }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: spacing(4),
    [breakpoints.down('md')]: {
      gap: spacing(1),
    },
  }),
  animation: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      position: 'absolute',
      top: '50%',
      left: '15%',
      transform: 'translateY(-50%)',
    },
    [breakpoints.up('lg')]: {
      left: '25%',
    },
    [breakpoints.up('xl')]: {
      left: '35%',
    },
  }),
};

export default function HomePageHero({ onPlayVideo }) {
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));
  const ButtonComponent = isMobile ? Button : ButtonXL;

  return (
    <div css={classes.root}>
      <Container css={classes.container}>
        <div css={classes.copySection}>
          <Typography
            sx={{ typography: { xs: 'h3', md: 'h2', lg: 'h1' }, maxWidth: { xs: '100%', md: 500 } }}
            variant="h1"
          >
            Better Grades, Less&nbsp;Studying
          </Typography>
          <Typography fontSize={{ sm: 'body3.fontSize' }} variant="body1">
            Simplify your toughest courses with Wizeprep
          </Typography>
          <div css={classes.ctas}>
            {isMobile && (
              <Button
                component={Link}
                fullWidth
                onClick={() => landingPageCTAClicked('hero', 'find_your_course')}
                size="large"
                to={coursesPath()}
                variant="contained"
              >
                Find Your Course
              </Button>
            )}
            <ButtonComponent
              color="inherit"
              fullWidth={isMobile}
              onClick={() => {
                landingPageCTAClicked('hero', 'watch_how_it_works');
                onPlayVideo(true);
              }}
              size="large"
              startIcon={<FontAwesomeIcon icon={faPlay} />}
              style={{ color: 'white' }}
              variant="outlined"
            >
              See How It Works
            </ButtonComponent>
          </div>
          {!isMobile && <HomePageHeroSearch />}
          <Typography>
            Looking for{' '}
            <MuiLink
              color="inherit"
              component={Link}
              onClick={() => landingPageCTAClicked('hero', 'mcat')}
              to={mcatLandingPath()}
            >
              MCAT Prep?
            </MuiLink>
          </Typography>
        </div>
      </Container>
      <HomePageHeroAnimation css={classes.animation} />
    </div>
  );
}
