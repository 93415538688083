import { useEffect } from 'react';

import { useStandardApiRequest } from 'my-core/hooks';

import { fetchSiteStatsStudentsHelped } from 'my-actions/SiteStatsActions';

export function useFetchStudentsHelped() {
  const STUDENTS_HELPED_FETCH_KEY = 'site_stats_students_helped';
  const { performRequest: performFetch, requestStatus: fetchStatus } = useStandardApiRequest({
    actionCreator: fetchSiteStatsStudentsHelped,
    requestKey: STUDENTS_HELPED_FETCH_KEY,
  });
  useEffect(() => {
    if (!fetchStatus) performFetch(STUDENTS_HELPED_FETCH_KEY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const studentsHelped = (Math.floor((fetchStatus?.result || 123_000) / 100) * 100).toLocaleString();

  return studentsHelped;
}
