import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMessageFromError } from 'my-core/error-utils';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import * as userActions from 'my-actions/UserActions';

class ResetPasswordForm extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const { resetStatus } = nextProps;
    if (prevState.submitting && resetStatus !== prevState.prevResetStatus) {
      return {
        submitting: resetStatus.error.body.reset_password_token !== 'is invalid',
        ...(resetStatus.error.body.error_code === 'unconfirmed' ?
          { unconfirmedAccount: true }
        : { errorMessage: getMessageFromError(resetStatus.error) }),
      };
    }
    return null;
  }

  submitForm = e => {
    e.preventDefault();
    if (this.state.submitting) return;
    const { password, passwordConfirmation, resetStatus } = this.state;
    const errorMessage = this.getErrorMessage();
    if (errorMessage) {
      this.setState({ errorMessage });
    } else {
      this.props.userActions.updateUserPassword({
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: this.props.token,
      });
      this.setState({ submitting: true, prevResetStatus: resetStatus });
    }
  };

  resendConfirmation = () => {
    const { resendConfirmationStatus, userActions } = this.props;
    const { loginEmail } = this.state;

    userActions.resendConfirmation(loginEmail);
    this.setState({ resendingConfirmation: true, prevResendConfirmationStatus: resendConfirmationStatus });
  };

  getErrorMessage() {
    const { password, passwordConfirmation } = this.state;
    if (!(password && passwordConfirmation)) return 'Provide a password and password confirmation';
    if (password !== passwordConfirmation) return 'Password and password confirmation do not match';
    if (password.length < 8) return 'Password must be at least 8 characters long';
  }

  render() {
    const { onClose, token } = this.props;
    const { errorMessage, password, passwordConfirmation, submitting, unconfirmedAccount } = this.state;
    return (
      <Dialog
        maxWidth="xs"
        onClose={onClose}
        open={!!token}
        PaperProps={{ component: 'form', onSubmit: this.submitForm }}
      >
        <DialogTitle>Reset Your Password</DialogTitle>
        <DialogContent>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          {unconfirmedAccount && (
            <Typography color="error">
              You must confirm your email address before continuing. To resend your confirmation email,
              <Button color="secondary" onClick={this.resendConfirmation} variant="text">
                Click here
              </Button>
            </Typography>
          )}
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            onChange={e => this.setState({ password: e.target.value })}
            type="password"
            value={password || ''}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            margin="normal"
            onChange={e => this.setState({ passwordConfirmation: e.target.value })}
            type="password"
            value={passwordConfirmation || ''}
          />
          <Button disabled={submitting} fullWidth style={{ marginTop: 8 }} type="submit" variant="contained">
            Change my password
          </Button>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(
  state => ({
    resetStatus: state.users.status.update_password,
  }),
  dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
  }),
)(ResetPasswordForm);
