import standardApiRequestAction from 'my-core/standardApiRequestAction';

export function fetchSiteStatsStudentsHelped(requestKey) {
  return standardApiRequestAction({
    path: '/api/v1/site_stats/students_helped',
    body: { static: true }, // static=true causes Cloudflare custom page rule to cache response
    requestKey,
  });
}

export function fetchSiteStatsAdminStats(params, requestKey) {
  return standardApiRequestAction({
    path: '/api/v1/site_stats/admin_stats',
    body: params,
    requestKey,
  });
}
