import memoize from 'memoize-one';

import { COURSE_GRADE_LEVEL_CONFIG_MAP, SUBJECT_CONFIG } from 'my-core/course-utils';

/*
  {
    [country_code]: {
      [school_type]: {
        [subject]: {
          [course name]: { order, slugs: [] }
        }
      }
    }
  }
 */
export default memoize(libCourses => {
  const result = {};
  // libCourses may not be defined -- AppBar storybook
  libCourses?.forEach(([grade, subject, code, country_code, slugs]) => {
    const gradeConfig = COURSE_GRADE_LEVEL_CONFIG_MAP[grade];
    const subjectConfig = SUBJECT_CONFIG[subject];

    if (!(gradeConfig && subjectConfig)) return;

    const countryCodes = country_code ? [country_code] : ['US', 'CA'];
    const schoolType = gradeConfig.school_type;
    // const subjectGroupConfig = subjectConfig.subject_grouping;
    countryCodes.forEach(cc => {
      const ccResult = (result[cc] ||= {});
      const schoolResult = (ccResult[schoolType] ||= {});
      const subjectResult = (schoolResult[subject] ||= {});

      const courseName =
        cc === 'CA' && schoolType === 'high_school' ?
          `${gradeConfig.ca_label || gradeConfig.label} ${subjectConfig.short_name || subjectConfig.name}`
        : code;

      const courseResult = (subjectResult[courseName] ||= { name: courseName, order: gradeConfig.order, slugs: [] });
      courseResult.slugs.push(...slugs);
    });
  });
  return result;
});
