import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';
import { useRef } from 'react';

import { getSubjectConfig } from 'my-core/course-utils';

import SubjectIcon from 'my-elements/SubjectIcon';

import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

gsap.registerPlugin(useGSAP);

const classes = {
  root: ({ breakpoints, spacing }) => ({
    display: 'flex',
    width: 'fit-content',

    [breakpoints.up('md')]: {
      flexDirection: 'column',
      gap: spacing(2),
    },
  }),
  row: ({ breakpoints, spacing }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: spacing(2),

    [breakpoints.down('md')]: {
      // I used margin instead of gap support a seamless animation. For the
      // mobile version, we run render the cards x2 and only animate 50% of the
      // length to create a continuous animation. In order for this to be
      // smooth, we need a space at the end of the cards to match the space
      // between the 'rows'
      marginRight: spacing(2),
    },
  }),
  card:
    color =>
    ({ breakpoints, palette, shape, spacing }) => ({
      minWidth: 264,
      flexShrink: 0,

      padding: spacing(2),
      borderRadius: shape.borderRadiusSm,
      border: `1px solid ${palette[color].main}`,

      [breakpoints.up('sm')]: {
        width: 360,
      },
    }),
  cardHeader: ({ breakpoints, spacing }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1.5),

    [breakpoints.up('sm')]: {
      gap: spacing(2),
    },
  }),
  cardTitle: ({ breakpoints, palette, typography }) => ({
    color: palette.grey[400],

    [breakpoints.up('sm')]: { ...typography.h5 },
  }),
  cardDescription: ({ breakpoints, palette, typography }) => ({
    color: palette.grey[500],

    [breakpoints.up('sm')]: { ...typography.body1 },
  }),
  subjectIcon: ({ breakpoints }) => ({
    width: 30,
    height: 30,

    [breakpoints.up('sm')]: {
      width: 40,
      height: 40,
    },
  }),
};

export default function HomePageHeroAnimation({ className }) {
  const rootRef = useRef();
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  useGSAP(
    () => {
      if (isMdDown) {
        gsap.to(rootRef.current, { x: '-50%', duration: 40, ease: 'none', repeat: -1 });
      } else {
        const rows = [...rootRef.current.children].reverse();
        gsap.fromTo(rows, { x: '85vw' }, { x: 0, duration: 4, stagger: 0.5, delay: 1 });
      }
    },
    { dependencies: [isMdDown], revertOnUpdate: true },
  );

  const cardRows = isMdDown ? [...COURSE_CARD_ROWS, ...COURSE_CARD_ROWS] : COURSE_CARD_ROWS;

  return (
    <div className={className} css={classes.root} ref={rootRef}>
      {cardRows.map((rowCards, rowIndex) => (
        <div key={rowIndex} css={classes.row}>
          {rowCards.map(renderCard)}
        </div>
      ))}
    </div>
  );

  function renderCard({ code, color, key: subject, name }) {
    return (
      <div key={code} css={classes.card(color)}>
        <div css={classes.cardHeader}>
          <SubjectIcon css={classes.subjectIcon} subject={subject} variant="outlined" />
          <Typography component="p" css={classes.cardTitle} variant="h6">
            {code}
          </Typography>
        </div>
        <Typography css={classes.cardDescription} variant="body2">
          {name}
        </Typography>
      </div>
    );
  }
}

const COURSE_CARD_ROWS = [
  [
    {
      code: 'CHEM 1302',
      name: 'Discovering Chemical Energetics',
      ...getSubjectConfig('chemistry'),
    },
  ],
  [
    {
      code: 'PHYS 100',
      name: 'Introductory Physics',
      ...getSubjectConfig('physics'),
    },
    {
      code: 'ACC 100',
      name: 'Introductory Financial Accounting',
      ...getSubjectConfig('accounting'),
    },
  ],
  [
    {
      code: 'ECON 102',
      name: 'Principles of Macroeconomics',
      ...getSubjectConfig('economics'),
    },
    {
      code: 'FIN 301',
      name: 'Corporation Finance',
      ...getSubjectConfig('finance'),
    },
    {
      code: 'CHEM 1302',
      name: 'Discovering Chemical Energetics',
      ...getSubjectConfig('chemistry'),
    },
  ],
  [
    {
      code: 'CALC 1000',
      name: 'Calculus I',
      ...getSubjectConfig('mathematics'),
    },
    {
      code: 'BIOCHEM 4053',
      name: 'Biochemistry I',
      ...getSubjectConfig('biochem'),
    },
    {
      code: 'PSYCH 100',
      name: 'Principles of Psychology',
      ...getSubjectConfig('psychology'),
    },
    {
      code: 'PHYS 100',
      name: 'Introductory Physics',
      ...getSubjectConfig('physics'),
    },
  ],
  [
    {
      code: 'COMPSCI 1032',
      name: 'Information Systems & Design',
      ...getSubjectConfig('compsci'),
    },
    {
      code: 'ORG CHEM 222',
      name: 'Introductory Organic Chemistry 2',
      ...getSubjectConfig('orgchem'),
    },
    {
      code: 'ENGG 200',
      name: 'Engineering Design and Communication',
      ...getSubjectConfig('engineering'),
    },
    {
      code: 'ECON 102',
      name: 'Principles of Macroeconomics',
      ...getSubjectConfig('economics'),
    },
    {
      code: 'FIN 301',
      name: 'Corporation Finance',
      ...getSubjectConfig('finance'),
    },
  ],
];
